<template>
  <div>
    <form class="form-box">
      <b-container class="container-box">
        <b-row class="no-gutters">
          <b-col>
            <h1 class="font-weight-bold text-uppercase">Bank Details</h1>
          </b-col>
        </b-row>
        <b-row
          class="no-gutters bg-white-border mt-4"
          v-bind:class="$isLoading ? 'd-none': 'd-block'"
        >
          <b-col class="px-4 px-sm-5 py-4">
            <b-row>
              <b-col md="6">
                <InputText
                  textFloat="Name"
                  placeholder="Name"
                  type="text"
                  name="name"
                  isRequired
                  v-model="form.name"
                  :isValidate="$v.form.name.$error"
                  :v="$v.form.name"
                />
              </b-col>
            </b-row>
            <b-row class="mt-4">
              <b-col md="6">
                <UploadFile
                  textFloat="Logo"
                  placeholder="Please choose file"
                  format="image"
                  name="logo"
                  text="*Please upload only file .png, .jpg only with 250 x 250 px and less than 10 MB"
                  :fileName="form.imageUrl"
                  v-model="form.imageUrl"
                  v-on:onFileChange="onImageChange"
                  v-on:delete="deleteImage"
                  isRequired
                  :v="$v.form.imageUrl"
                  :isValidate="$v.form.imageUrl.$error"
                  :checkRegRatio="true"
                />
                <div
                  class="preview-box square-ratio bank-img"
                  v-bind:style="{ 'background-image': 'url('+form.imageUrl+')' }"
                >
                  <img
                    src="@/assets/images/icons/loading.svg"
                    class="loading"
                    alt="loading"
                    v-if="isLoading"
                  />
                </div>
              </b-col>
              <b-col md="6">
                <UploadFile
                  textFloat="Mobile App Logo"
                  placeholder="Please choose file"
                  format="image"
                  name="mobileapplogo"
                  text="*Please upload only file .png, .jpg only with 250 x 250 px and less than 10 MB"
                  :fileName="form.mobileBankingImageUrl"
                  v-model="form.mobileBankingImageUrl"
                  v-on:onFileChange="onImageAppLogoChange"
                  v-on:delete="deleteImageAppLogo"
                  :checkRegRatio="true"
                />
                <div
                  class="preview-box square-ratio bank-img"
                  v-bind:style="{ 'background-image': 'url('+form.mobileBankingImageUrl+')' }"
                >
                  <img
                    src="@/assets/images/icons/loading.svg"
                    class="loading"
                    alt="loading"
                    v-if="isLoadingAppLogo"
                  />
                </div>
              </b-col>
            </b-row>

            <b-row class="mt-5">
              <b-col md="6">
                <button
                  v-if="id != 0"
                  type="button"
                  class="btn btn-danger btn-details-set text-uppercase mr-md-2"
                  @click="openModalDelete(form.name)"
                  :disabled="isDisable"
                >Delete</button>
                <b-button to="/bank" class="btn-details-set" :disabled="isDisable">CANCEL</b-button>
              </b-col>
              <b-col md="6" class="text-sm-right">
                <button
                  type="button"
                  class="btn btn-success btn-details-set ml-md-2 text-uppercase"
                  @click="saveForm(0)"
                  :disabled="isDisable"
                >Save</button>
                <button
                  type="button"
                  class="btn btn-success btn-details-set ml-md-2 text-uppercase"
                  @click="saveForm(1)"
                  :disabled="isDisable"
                >Save & Exit</button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </form>

    <!-- Modal Result -->
    <ModalAlert ref="modalAlert" :text="modalMessage" />
    <ModalAlertError ref="modalAlertError" :text="modalMessage" />
    <ModalAlertConfirm
      msg="ยืนยันการลบ ?"
      :text="modalMessage"
      btnConfirm="Delete"
      colorBtnConfirm="danger"
      btnCancel="Close"
      ref="isModalAlertConfirm"
      @confirm="btnDelete"
    />
  </div>
</template>

<script>
import InputText from "@/components/inputs/InputText";
import UploadFile from "@/components/inputs/UploadFile";
import ModalAlert from "@/components/modal/alert/ModalAlert";
import ModalAlertError from "@/components/modal/alert/ModalAlertError";
import ModalAlertConfirm from "@/components/modal/alert/ModalAlertConfirm";
import { required } from "vuelidate/lib/validators";

export default {
  name: "Bank",
  components: {
    InputText,
    UploadFile,
    ModalAlert,
    ModalAlertError,
    ModalAlertConfirm,
  },
  data() {
    return {
      id: this.$route.params.id,
      showImage: "",
      showImageAppLogo: "",
      isDisable: false,
      isLoading: false,
      isLoadingAppLogo: false,
      existId: "",
      modalMessage: "",
      form: {
        id: 0,
        name: null,
        imageUrl: "",
        mobileBankingImageUrl: "",
      },
    };
  },
  validations: {
    form: {
      name: { required },
      imageUrl: { required }
    },
  },
  mounted: async function () {
    this.$isLoading = true;
    await this.getData();
    this.$isLoading = false;
  },
  methods: {
    getData: async function () {
      let data = await this.$callApi(
        "get",
        `${this.$baseUrl}/api/bank/${this.id}`,
        null,
        this.$headers,
        null
      );
      if (data.result == 1) {
        this.form = data.detail;
      }
    },
    onImageChange(img) {
      this.isLoading = true;
      this.isDisable = true;
      var reader = new FileReader();
      reader.readAsDataURL(img);
      reader.onload = async () => {
        this.images = await this.saveImagetoDb(reader.result);
        this.isLoading = false;
        this.isDisable = false;
        this.form.imageUrl = this.images;
      };
    },
    deleteImage(img) {
      this.form.imageUrl = null;
    },
    onImageAppLogoChange(img) {
      this.isLoadingAppLogo = true;
      this.isDisable = true;
      var reader = new FileReader();
      reader.readAsDataURL(img);
      reader.onload = async () => {
        this.images = await this.saveImagetoDb(reader.result);
        this.isLoadingAppLogo = false;
        this.isDisable = false;
        this.form.mobileBankingImageUrl = this.images;
      };
    },
    deleteImageAppLogo(img) {
      this.form.mobileBankingImageUrl = null;
    },
    saveImagetoDb: async function (img) {
      var imgData = {
        base64: img,
      };

      let data = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/image/save`,
        null,
        this.$headers,
        imgData
      );

      if (data.result == 1) {
        return data.detail.url;
      }
    },
    saveForm: async function (flag) {
      this.$v.form.$touch();
      if (this.$v.form.$error) return;

      this.isDisable = true;
      let resData = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/bank/save`,
        null,
        this.$headers,
        this.form
      );
      this.modalMessage = resData.message;
      this.isDisable = false;
      if (resData.result == 1) {
        this.existId = resData.detail.id;
        this.$refs.modalAlert.show();

        if (flag === 1) {
          setTimeout(this.routeToUserList, 2000);
        } else {
          if (this.id != 0) {
            this.$isLoading = true;
            this.getData();
            this.$isLoading = false;
          } else {
            this.id = this.existId;
            this.form.id = this.existId;
            this.$router.push({ path: `/bank/details/${this.existId}` });
          }
        }
      } else {
        this.$refs.modalAlertError.show();
      }
    },
    btnDelete: async function () {
      this.$refs.isModalAlertConfirm.hide();

      let resData = await this.$callApi(
        "delete",
        `${this.$baseUrl}/api/bank/remove/${this.id}`,
        null,
        this.$headers,
        null
      );
      this.modalMessage = resData.message;
      if (resData.result == 1) {
        this.$refs.modalAlert.show();
        setTimeout(this.routeToUserList, 2000);
      } else {
        this.$refs.modalAlertError.show();
      }
    },
    openModalDelete(name) {
      this.modalMessage = "คุณต้องการลบ " + name + " ใช่หรือไม่?";
      this.$refs.isModalAlertConfirm.show();
    },
    routeToUserList() {
      this.$router.push("/bank");
    },
  },
};
</script>